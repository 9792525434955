import React from 'react';
import PropTypes from 'prop-types';
// import connectToParent from 'penpal/lib/connectToParent';

import './ExternalTemplate.css';
import { getUrl } from '../documentViewer/documentViewer';

class ExternalTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.sendDocument = this.sendDocument.bind(this);
    this.iframeRef = React.createRef();
    this.print = this.print.bind(this);
    this.state = {
      url: '',
    };
  }

  async componentDidMount() {
    this.iframe = this.iframeRef.current;
    // establish parent connection to connect print function
    // this.parentConnection = await connectToParent({
    //   methods: {
    //     print: this.print,
    //   },
    //   childOrigin: '*',
    // }).promise;
  }

  // Creates a proxy iframe to print the inner iframe
  // Solves cross origin issues.
  print() {
    // Invoked print function on nextcert layout
    this.props.document.print = true;
    this.sendDocument();
    this.props.document.print = false;
  }

  sendDocument() {
    if (!this.iframe) return;
    const frame = this.iframe.contentWindow;
    if (!frame) return;
    frame.postMessage(this.props.document, '*');
  }

  render() {
    return (
      <div className="full">
        <iframe
          ref={this.iframeRef}
          id="external-frame"
          className="external-template"
          frameBorder="0"
          src={getUrl(this.props.document)}
          onLoad={this.sendDocument}
        />
      </div>
    );
  }
}

ExternalTemplate.propTypes = {
  document: PropTypes.object.isRequired,
};

export default ExternalTemplate;
