import React, { Component } from 'react';
import { get } from 'lodash';
import connectToParent from 'penpal/lib/connectToParent';
import DocumentViewer from './documentViewer';
import { documentTemplateTabs, inIframe } from './utils';

export const HEIGHT_OFFSET = 60; // Height offset to prevent double scrollbar in certain browsers
const NEXTCERT_LAYOUT = 'nextcert-layout';

const VERSION = 'v0.9.0.6';

class DocumentViewerContainer extends Component {
  constructor(props) {
    super(props);

    this.handleDocumentChange = this.handleDocumentChange.bind(this);
    this.selectTemplateTab = this.selectTemplateTab.bind(this);
    this.updateParentHeight = this.updateParentHeight.bind(this);
    this.updateParentTemplateTabs = this.updateParentTemplateTabs.bind(this);
    this.handleObfuscation = this.handleObfuscation.bind(this);
    this.state = {
      parentFrameConnection: null,
      document: null,
      tabIndex: 0,
    };
    this.print = this.print.bind(this);
  }

  updateHeightWhenResize() {
    window.addEventListener('resize', this.updateParentHeight);
  }

  // Use postMessage to update iframe's parent to scale the height
  async updateParentHeight() {
    if (inIframe()) {
      const { parentFrameConnection } = this.state;
      const parent = await parentFrameConnection;
      if (parent.updateHeight) {
        // Returns 0 height when using NEXTCERT_LAYOUT
        // The window height has to be taken from iframe.
        // A simple fix would be to set a min height if the height is 0
        let height = Math.max(document.documentElement.offsetHeight, 650);
        await parent.updateHeight(height);
      }
    }
  }

  // Use postMessage to update iframe's parent on the selection of templates available for this document
  async updateParentTemplateTabs() {
    const templates = await documentTemplateTabs(this.state.document);
    this.setState({ templates });
    if (inIframe()) {
      const { parentFrameConnection } = this.state;
      const parent = await parentFrameConnection;
      if (parent.updateTemplates) {
        await parent.updateTemplates(documentTemplateTabs(this.state.document));
      }
    }
  }

  selectTemplateTab(tabIndex) {
    this.setState({ tabIndex });
  }

  handleDocumentChange(document) {
    this.setState({ document });
    this.updateParentTemplateTabs();
  }

  async handleObfuscation(field) {
    if (inIframe()) {
      const { parentFrameConnection } = this.state;
      const parent = await parentFrameConnection;
      if (parent.handleObfuscation) {
        parent.handleObfuscation(field);
      }
    }
  }

  // Function will not be invoked if it's being overriden by ExternalTemplate aka nextcert layout
  print() {
    if (!this.state.document) return;
    // get the type of cert
    const type = get(
      this.state.document,
      'additionalData.template.type',
      '',
    ).toLowerCase();
    // print function will not be invoked for nextcert-layout
    if (type === NEXTCERT_LAYOUT) {
      return;
    }

    // invoke print functionality for react certs
    window.print();
  }

  componentDidUpdate() {
    this.updateParentHeight();
  }

  componentDidMount() {
    console.log(VERSION);

    const renderDocument = this.handleDocumentChange;
    const selectTemplateTab = this.selectTemplateTab;

    if (inIframe()) {
      const parentFrameConnection = connectToParent({
        methods: {
          renderDocument,
          selectTemplateTab,
          print: this.print,
        },
      }).promise;
      this.setState({ parentFrameConnection });
    }
    this.updateHeightWhenResize();
  }

  render() {
    if (!this.state.document) {
      return (
        <div style={{ position: 'relative', height: '100vh' }}>
          <p
            style={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              fontSize: 11,
              display: 'none',
            }}
          >
            {VERSION}
          </p>
        </div>
      );
    }
    return (
      <DocumentViewer
        document={this.state.document}
        tabIndex={this.state.tabIndex}
        handleHeightUpdate={this.updateParentHeight}
        handleObfuscation={this.handleObfuscation}
      />
    );
  }
}
export default DocumentViewerContainer;
